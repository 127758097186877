import Theme from '@/shared/app/model/Theme';
import defaultTheme from '@/shared/app/theme/default.theme';

export const eliorTheme: Theme = {
  ...defaultTheme,
  customerName: 'Elior',
  primaryColor: '#e15e15',
  secondaryColor: '#607d8b',
  accentColor: '#ffc107',
  errorColor: '#f44336',
  warningColor: '#ff9800',
  infoColor: '#3f51b5',
  successColor: '#4caf50',
};
